// const API_URL = 'http://localhost:3000';
const API_URL = 'https://photoshots.fly.dev';

// const APP_URL = 'http://localhost:1234';
const APP_URL = 'https://movieaiavatars.com';

async function getRequest(url = '') {
  const response = await fetch(url, {
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: { 'Content-Type': 'application/json' }
  });
  return response.json();
}

async function postRequest(url = '', data = {}) {
  const response = await fetch(url, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  });
  return response.json();
}

async function fileUploadPostRequest(url = '', data) {
  const response = await fetch(url, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    body: data
  });
  return response.json();
}

// set order id
var orderId = new URLSearchParams(window.location.search).get('order_id');
if (orderId !== null) {
  localStorage.setItem('orderId', orderId);
} else {
  orderId = localStorage.getItem('orderId');
}


//
// index.html
//

//  dummy request to get backend app out of sleep state
if (document.body.classList.contains('page-index')) {
  getRequest(API_URL);
}


//
// select.htmml
//

if (document.body.classList.contains('page-select')) {
//the best package
  document.getElementById('order-button')
    .addEventListener('click', event => {
      const button = event.target;
      const loader = button.querySelector('.loader');
      // Show the loader
      loader.style.display = 'inline-block';
      // More expensive product
      const price_id = 'price_1O4e6YAxuElKaRsXpKNFSciz';
      const endopint = API_URL + '/api/v1/orders';
      const cancel_url = APP_URL + '/select.html';
      const success_url = APP_URL + '/upload.html';

      const requestData = {
        order: {
          stripe_price_id: price_id,
          stripe_cancel_url: cancel_url,
          stripe_success_url: success_url
        }
      }
      postRequest(endopint, requestData)
        .then((order) => {
          // console.log(order);
          localStorage.setItem('orderId', order.id);
          window.location.replace(order.stripe_payment_url);
        });
    });


  document.getElementById('standard-order-button')
  //small package
    .addEventListener('click', event => {
      const button = event.target;
      const loader = button.querySelector('.loader');
      // Show the loader
      loader.style.display = 'inline-block';
      // Cheaper product
      const price_id = 'price_1OG3d8AxuElKaRsXfAH5LAGM';
      const endopint = API_URL + '/api/v1/orders';
      const cancel_url = APP_URL + '/select.html';
      const success_url = APP_URL + '/upload.html';

      const requestData = {
        order: {
          stripe_price_id: price_id,
          stripe_cancel_url: cancel_url,
          stripe_success_url: success_url
        }
      }
      postRequest(endopint, requestData)
        .then((order) => {
          // console.log(order);
          localStorage.setItem('orderId', order.id);
          window.location.replace(order.stripe_payment_url);
        });
    });


}


//
// upload.html
//

if (document.body.classList.contains('page-upload')) {
  function compressFiles(files) {
    const zip = new JSZip();
    const catalog = zip.folder('data');

    let promises = files.map(file => {
      if (file.name.toLowerCase().endsWith('.heic')) {
        return heic2any({
          blob: file,
          toType: "image/jpeg",
          quality: 0.8
        })
        .then((jpegBlob) => {
          // Here jpegBlob is a blob of JPEG image
          return {name: file.name.replace(/\.heic$/i, '.jpg'), blob: jpegBlob};
        });
      } else {
        return Promise.resolve({name: file.name, blob: file});
      }
    });
    
    Promise.all(promises).then(results => {
      results.forEach(result => {
        catalog.file(result.name, result.blob, { base64: true });
    });

    zip.generateAsync({ type: 'blob' })
      .then(data => {
        uploadImages(data);
        });
      });
  }

  function uploadImages(data) {
    const endopint = API_URL + '/api/v1/orders/' + orderId + '/upload_images';

    const formData = new FormData();
    formData.append('order[training_images]', data, 'data.zip');

    fileUploadPostRequest(endopint, formData)
      .then((order) => {
        // console.log(order);
        window.location.replace(APP_URL + '/styles.html?order_id=' + orderId);
      });
  }

  Dropzone.options.imageUploadForm = {
    processingmultiple: (files) => {
      compressFiles(files);
    },

    clickable: "#dropZone",
    paramName: "file",
    addRemoveLinks: true,
    maxFilesize: 12,
    maxFiles: 20,
    parallelUploads: 20,
    acceptedFiles: 'image/jpeg,image/jpg,image/heic',
    autoProcessQueue: false,
    uploadMultiple: true,
    url: '/dontcometomyghetto',
  };

  document.getElementById('submit_form')
    .addEventListener('click', event => {
      const button = event.target;
      const loader = button.querySelector('.loader');
      // Show the loader
      loader.style.display = 'inline-block';
      button.textContent = 'Uploading your pictures...';

      event.preventDefault();
      document.getElementById('image-upload-form').dropzone.processQueue();
    });
}


//
// styles.html
//

// if (document.body.classList.contains('page-styles')) {
//   getRequest(API_URL + '/api/v1/orders/' + orderId)
//     .then((order) => {
//       window.num_styles = order['num_styles'];
//     })

//   function chooseStylesRequest(selected_styles) {
//     const endopint = API_URL + '/api/v1/orders/' + orderId + '/choose_styles';

//     const requestData = {
//       order: {
//         image_styles: selected_styles
//       }
//     }

//     postRequest(endopint, requestData)
//       .then((order) => {
//         // console.log(order);
//         window.location.replace(APP_URL + '/success.html?order_id=' + orderId);
//       });
//   };
// }


//
// success.html
//

if (document.body.classList.contains('page-success')) {
  getRequest(API_URL + '/api/v1/orders/' + orderId)
    .then((order) => {
      window.upscaling = order['upscaling'];
      // window.generatedImages = order['generated_images'];
      window.renderImages(order['generated_images']);
    })
}